export const SocketEvent = {
  CONNECTED_EVENT: "connected",
  DISCONNECTED_EVENT: "disconnect",
  COMMENT_POST_EVENT: "commentPost",
  LIKE_POST_EVENT: "likePost",
  MENTIONED_YOU_IN_CHAT:"chatMention",
  LIKE_COMMENT_EVENT: "likeComment",
  NEW_MESSAGE_EVENT: "newMessage",
  ACCEPT_REQUEST_EVENT: "acceptRequest",
  SEND_FRIEND_REQUEST_EVENT: "friendRequest",
  CANCEL_SENT_FRIEND_REQUEST_EVENT: "cancelFriendRequest",
  GROUP_MEMBER_ADDED:"groupMemberAdded",
  CHAT_TOGGLE:"chatToggle",
};

export const Constants = {
  LIKE_POST: "LIKEPOST",
  LIKE_COMMENT: "LIKECOMMENT",
  COMMENT_POST: "COMMENTPOST",
  FRIEND_REQUEST:"FRIENDREQUEST"
};

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { store } from "./store/store.js";
import reportWebVitals from "./reportWebVitals.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.scss";
import { Provider } from "react-redux";
import { SocketProvider } from "./store/SocketContext";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <Toaster position="top-right" />
        <SocketProvider>
          <App />
        </SocketProvider>
    </Provider>
);
reportWebVitals();
